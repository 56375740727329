<template>
  <section v-if="isMembersAppEnabled || isFromQR">
    <div v-if="isLoading">
      <b-spinner
        type="grow"
        small
        variant="primary"
        class="d-block mx-auto mt-3"
      />
    </div>
    <div v-else-if="hasErrors">
      <div class="mt-3 text-danger text-center">
        {{ $t("error-message.error-message") }}
      </div>
    </div>
    <div v-else>
      <!-- Profile completion progress bar -->
      <profile-progress-bar
        v-if="member !== '' && isCurrentMember"
        :member-info="member"
        class="alerta"
      />
      <!-- Profile Content -->
      <b-row>
        <!-- Banner and wall -->
        <b-col lg="7">
          <profile-progress-bar
            v-if="member !== '' && isCurrentMember"
            :member-info="member"
            class="alerta2"
          />
          <profile-details
            :name="`${member.name} ${member.surname ? member.surname : ''}`"
            :headline="member.headline"
            :member="member"
            :brief-info="member.organizations"
            :avatar-placeholder="avatarPlaceholder"
            :profile-image="member.avatarURL"
            :header-background="member.backgroundUrl"
            fit-profile-image
            :has-relation="hasRelation"
            :has-action-buttons="hasActionButtons"
            :can-edit="canEdit"
            @open-chat="handleSidebarChange"
            @request-meeting="handleCalendarMeetingOpen"
            @edit="handleEditProfile"
            @removed-relation="handleRemovedRelation"
            @request-connection="wantConnection = true"
          />
          <!-- Edit member modal -->
          <!--member-edit-modal
            v-model="isEditModalVisible"
            :member="member"
            :classifiers="classifiers"
            @profile-updated="handleProfileUpdated"
          /-->
          <!--About card in small devices-->
          <about-member
            :member="member"
            :classifiers="classifiers"
            :has-relation="hasRelation || isStaff"
            class="d-lg-none"
          />
          <!-- Member posts -->
          <list-container-widget
            v-if="enabledApps.posts"
            id="posts-list"
            class="posts-list"
            :app-id="13"
            :view-more-location="getLocation('posts-list')"
            stretch
          >
            <posts-list :user-key="member.userKey" />
          </list-container-widget>
        </b-col>
        <!-- About me, connections and other -->
        <b-col lg="5">
          <!-- Member Details -->
          <about-member
            :key="keyAbout"
            :member="member"
            :classifiers="classifiers"
            class="d-none d-lg-block"
            :has-relation="hasRelation || isStaff"
            @profile-updated="handleProfileUpdated"
          />
          <!-- Twitter -->
          <div v-if="hasTwitterFeed" class="vh-75 mb-2">
            <twitter-feed-widget :src="twitterSrc" />
          </div>
          <!--User Activity-->
          <!-- <b-card :title="$t('activity.title')">
            <div class="mt-1">
              <div class="d-flex flex-column justify-content-center align-items-center mb-1">
                <img class="placeholder" :src="activityPlaceholder">
                <p class="mt-2 text-primary font-weight-bold">
                  {{ $t("members.no-activity-message") }}
                </p>
              </div>
            </div>
          </b-card> -->

          <!-- Member Spaces -->
          <b-card
            v-if="enabledApps.subcommunities"
            class="mt-2"
            :title="$t('members.my-communities')"
          >
            <div v-if="member.subCommunities && member.subCommunities.length > 0">
              <div
                v-for="community in member.subCommunities"
                :key="community.key"
                class="mb-75"
              >
                <b-link @click="communityLocation(community.community)">
                  <collective-logo :logo="communityLogo(community)" />
                  <span class="font-weight-bold ml-50">{{translateTranslationTable($store.state.locale.currentLocale, community.community.name)}}
                    <small><b-badge pill :variant="getColor(community.community.mainType)" class="ml-25">{{community.community.mainType}}</b-badge></small>
                  </span>
                </b-link>
              </div>
            </div>
            <div v-else>
              <div
                class="d-flex flex-column justify-content-center align-items-center mb-1"
              >
                <img
                  class="placeholder placeholder__small"
                  :src="communitiesPlaceholder"
                />
                <p class="text-primary font-weight-bold">
                  {{ $t("members.no-activity-message") }}
                </p>
              </div>
            </div>
          </b-card>

          <!-- Connections -->
          <b-card v-show="showConnections && isloggedMember">
            <member-connections
              v-if="member"
              :member="member"
              :is-staff="isStaff"
              :isMySelf="false"
              :want-connection="wantConnection"
              @relations="getRelations"
              @wantConnections="wantConnection = false"
            />
          </b-card>
        </b-col>
        <!-- request meeting -->
        <request-meeting-modal
          v-model="isRequestMeetingVisible"
          :member="member"
        />
        <!-- sortedClassifiers -->
        <!--classifiers-modal
          v-model="isClassifiersModalVisible"
          :item-key="member.key != null ? member.key : member"
          morph-type="member"
          :classifiers="classifiers"
          model-type="member"
          @profile-updated="handleProfileUpdated"
        /-->
        <!-- custom fields -->
        <!--customfields-modal
          v-model="isCustomFieldsModalVisible"
          :item="member"
          morph-type="member"
          @profile-updated="handleProfileUpdated"
        /-->
        <!-- Roles -->
        <!--b-modal
          v-model="isRolesModalVisible"
          :title="$t('members.roles.edit')"
          :ok-title="$t('form.actions.save')"
          no-close-on-backdrop
          @ok="setRoles"
        >
          <b-form-group v-if="isStaff">
            <label for="profile-role"> {{ $t("members.roles.title") }}</label>
            <v-select
              id="type"
              v-model="rolesSelected"
              multiple
              :placeholder="$t('form.select-placeholder')"
              :options="rolesOptions"
              label="name"
            />
          </b-form-group>
        </b-modal-->
        <!-- Unsubscribe modal -->
        <!--b-modal
          v-model="isOpenedUnsubscribeModal"
          :title="$t('unsubscribe-community.cancel-subscription')"
          size="lg"
          hide-footer
        >
          <feather-icon
            size="30"
            icon="AlertTriangleIcon"
            class="d-block mx-auto text-danger mb-1"
          />
          <h5 class="text-center mb-2">
            {{
              $t("unsubscribe-community.message", {
                communityName: currentCollective.name,
              })
            }}
          </h5>
          <div class="d-flex justify-content-center mb-2 flex-wrap">
            <b-button
              variant="primary"
              class="mr-md-2 mb-1 mb-md-0"
              @click="isOpenedUnsubscribeModal = false"
            >
              {{ $t("unsubscribe-community.stay-subscribed") }}
            </b-button>
            <b-button
              v-if="loggedMember"
              variant="outline-danger"
              @click="unsubscribeCommunity()"
            >
              {{ $t("unsubscribe-community.cancel-subscription") }}
            </b-button>
          </div>
        </b-modal-->
      </b-row>
    </div>
  </section>
  <section v-else>
    <uninstalled-addon
      addonKey="members" 
    />
  </section>
</template>

<script>
import MembersPlaceholder from "@/assets/images/placeholders/light/members.svg";
import CommunitiesPlaceholder from "@/assets/images/placeholders/light/communities.svg";
import PostsList from "@/views/apps/posts/components/PostsList.vue";
import Service from "@/config/service-identifiers";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ProfileDetails from "@core/components/profile/ProfileDetails.vue";
import TwitterFeedWidget from "@/views/apps/twitter/widgets/TwitterFeedWidget.vue";
import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
import { CHAT_ACTIONS } from "@/views/apps/chat/constants/chat-store-constants";
import AboutMember from "@core/components/members/AboutMember.vue";
import RequestMeetingModal from "@/views/apps/member/modals/RequestMeetingModal.vue";
// import MemberEditModal from "@/views/apps/member/modals/MemberEditModal.vue";
// import CustomfieldsModal from "@core/components/modal/CustomfieldsModal.vue";
// import ClassifiersModal from "@core/components/modal/ClassifiersModal.vue";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import CollectiveLogo from "@core/components/collective-logo/CollectiveLogo.vue";
import vSelect from "vue-select";
import MemberConnections from "./components/MemberConnections.vue";
import ProfileProgressBar from "@/views/myself/components/ProfileProgressBar.vue";
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { randomString } from '@/@core/utils/utils';
import UninstalledAddon from '@/@core/components/UninstalledApps/UninstalledAddon.vue'

export default {
  name: "MemberDetails",
  components: {
    PostsList,
    ProfileDetails,
    TwitterFeedWidget,
    AboutMember,
    RequestMeetingModal,
    MemberConnections,
    // MemberEditModal,
    // ClassifiersModal,
    // CustomfieldsModal,
    ListContainerWidget,
    CollectiveLogo,
    vSelect,
    ProfileProgressBar,
    UninstalledAddon
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      member: null,
      isLoading: true,
      hasErrors: false,
      isChatVisible: false,
      isEditModalVisible: false,
      isRequestMeetingVisible: false,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      isRolesModalVisible: false,
      rolesSelected: [],
      rolesOptions: [],
      isOpenedUnsubscribeModal: false,
      aviso: false,
      camposVacios: {},
      empty: 0,
      lastLoadedPage: 1,
      hasRelation: false,
      keyAbout: "keyAbout",
      wantConnection: false,
    };
  },
  computed: {
    max() {
      return 12;
    },
    location() {
      return {
        name: "globalMemberSettings",
        params: { communityId: this.$route.params.communityId },
      };
    },
    allFields() {
      return [
        this.member.name,
        this.member.surname,
        this.member.emails,
        this.member.headline,
        this.member.links,
        this.member.organizations,
        this.member.summary,
        this.member.backgroundURL,
        this.member.avatarURL,
        this.member.custom,
        this.member.classifiers,
      ];
    },
    percentaje() {
      const result =
        ((this.allFields.length - this.empty) * 100) / this.allFields.length;
      return Math.round(result);
    },
    moreMarg() {
      return {
        "margin-top": "-60px",
      };
    },
    menosMarg() {
      return {
        "margin-top": "-30px",
      };
    },
    twitterSrc() {
      const twitterLink = (this.member?.links || []).find(
        ({ site }) => site === "Twitter"
      );
      return twitterLink?.url;
    },
    hasTwitterFeed() {
      return (
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].twitter &&
        this.twitterSrc
      );
    },
    currentUser() {
      return this.$store.getters.loggedUser;
    },
    currentMember() {
      return this.$store.getters.loggedMember;
    },
    hasActionButtons() {
      return this.member?.key !== this.currentMember?.key;
    },
    isCurrentMember() {
      if (this.member?.key == this.currentMember?.key) {
        return true;
      }
      return false;
    },
    canEdit() {
      if (this.member != null && this.currentMember != null) {
        return (
          this.member.key === this.currentMember.key ||
          this.$store.getters.currentCollective.isStaff
        );
      }
      return this.$store.getters.currentCollective.isStaff;
    },
    avatarPlaceholder() {
      return MembersPlaceholder;
    },
    communitiesPlaceholder() {
      return CommunitiesPlaceholder;
    },
    classifiersData() {
      if (this.$store.getters.classifiers.myMember) {
        return this.$store.getters.classifiers.myMember.unpaginated;
      }
      return [];
    },
    classifiers() {
      return this.classifiersData.reduce((acc, current) => {
        const x = acc.findIndex((item) => item.key === current.key);
        if (x === -1) {
          return acc.concat([current]);
        }
        acc[x] = current;
        return acc;
      }, []);
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    communityRoles() {
      return this.$store.getters.roles?.unpaginated;
    },
    memberRoles() {
      return this.$store.getters.memberRoles?.unpaginated;
    },
    username() {
      return this.$route.params.username;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    canUnsuscribe() {
      return this.loggedMember?.username;
    },
    isloggedMember() {
      return this.$store.getters.loggedMember.username === this.$route.params.username;
    },
    fromQr() {
      // eslint-disable-next-line no-prototype-builtins
      return this.$route?.query?.hasOwnProperty('from_qr');
    },
    apps(){
      return this.$store.getters.apps?.apps
    },
    enabledAppsKeys() {
      const installedApps=[];
      Object.values(this.apps).forEach(item => {installedApps.push(item.nameSingular)})
      return installedApps;
    },
    enabledApps() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps];
    },
    isMembersAppEnabled() {
      return this.enabledAppsKeys?.filter((item) => item === 'member').length > 0;
    },
    isFromQR() {
      if(this.currentCollective.key === '945a43f5-a8bf-4beb-bf8f-f97d5590cb97'){  //gent born
        return true;
      }
      return false;
    },
    membersApp() {
      return Object.values(this.enabledAppsKeys).find(({ id }) => id === 50)
    },
    membersAppTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.membersApp?.customizationName
      );
    },
    showConnections() {
      return false;
    }
  },
  watch: {
    async username() {
      await this.fetchUser(this.username);

      await this.fetchClassifiers();
      await this.getMemberRoles();
      this.rolesSelected = this.memberRoles;
      this.getRoles();
      await this.isComplete();
    },
    classifiers(newValue) {
      return newValue;
    },
    hasRelation(v) {
      this.keyAbout = randomString(10);
    },
    wantConnection(value) {
      return value;
    },
  },

  async created() {
    console.log('username', this.username);
    await this.fetchUser(this.username);
    if (this.fromQr) {
      await this.createConection();
    }
    await this.fetchDataFriends(this.loggedMember.key);
    await this.fetchClassifiers();
    await this.getMemberRoles();
    this.rolesSelected = this.memberRoles;
    this.getRoles();
    await this.isComplete();
  },
  methods: {
    async fetchDataFriends(memberKey, forceAPICall = true) {
      const response = await this.$store.dispatch('getItems', {
        itemType: 'memberRelation',
        customName: "myMemberRelationshipsUsingConnects",
        page: this.lastLoadedPage,
        forceAPICall,
        perPage: 16,
        requestConfig: { orderByDate: -1, memberKey },
      });
        this.hasRelation = response.data.filter(item => item.username === this.username).length > 0
        this.keyAbout = randomString(10);
    },
    communityLogo(community) {
      return community?.community?.logoURL;
    },
    isComplete() {
      this.allFields.map((campo) => {
        if (
          campo === null ||
          (Array.isArray(campo) && campo?.length === 0) ||
          campo === ""
        ) {
          this.empty += 1;
          this.aviso = true;
        }
      });
    },
    goSettings() {
      const item = { slug: this.$route.params.communitySlug || this.$route.params.communityId, subdomain: this.$store.getters.currentCollective?.subdomain };
      const community = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL?.replace(
        '{subdomain}',
        item.subdomain || 'app',
      )?.replace('{slug}', item.slug);

      window.location.href = `${community}/myself/settings`;
    },
    async fetchUser() {
      this.updateBreadcrumbs();
      //console.log('start fetching member data...');
      this.isLoading = true;
      this.hasErrors = false;

      // get space
      // console.log('this.$route.params.communityId', this.$route.params.communityId);
      const currentSpaceSlug = this.$route.params.communityId;  //this.$store.getters.currentCollective.slug;
      console.log('currentSpaceSlug', currentSpaceSlug);

      try {
        // TODO: use member store
        //console.log('enter in try...');
        const response = await this.$store.$service[Service.BackendClient].get(
          "/communityMembers",
          {
            params: {
              communitySlug: currentSpaceSlug,
              username: this.username,
              isForProfile: true,
            },
          }
        );
        this.isLoading = false;
        //console.log('response', response);
        const member = response.data.data[0];
        if (!member) {
          this.$router.push({
            name: "error",
            params: {
              communityId: this.$route.params.communityId,
              error: "404",
            },
          });

          return;
        }

        this.member = member;
        this.updateBreadcrumbs();
        this.isLoading = false;
        return member;
      } catch (error) {
        console.log('Error:', error);
        this.hasErrors = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("error-message.load-failed"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    handleSidebarChange(isVisible) {
      if (isVisible) {
        const room = `${this.$store.getters.currentCollective.slug}-${
          parseInt(this.$store.getters.loggedMember.uniqueNumber) +
          parseInt(this.member.uniqueNumber)
        }`;
        this.$store.dispatch(CHAT_ACTIONS.openIndividualChat, {
          user: this.member,
          room,
        });
      } else {
        this.$store.dispatch(CHAT_ACTIONS.closeIndividualChat);
      }
    },
    handleEditProfile() {
      this.isEditModalVisible = true;
    },
    async handleProfileUpdated() {
      await this.fetchUser(this.username);
      this.fetchClassifiers();
      this.isComplete();
    },
    handleCalendarMeetingOpen() {
      this.isRequestMeetingVisible = true;
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.membersAppTitle || "members.breadcrumb-text.members-list",
          to: { name: "members" },
        },
        {
          text: this.member
            ? `${this.member.name} ${this.member.surname ? this.member.surname : ''}`
            : "members.breadcrumb-text.member-details",
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
    async fetchClassifiers() {
      await this.$store.dispatch("getItems", {
        itemType: "backoffice/classifiers",
        customName: "classifiers",
        storedKey: "myMember",
        page: 1,
        forceAPICall: true,
        requestConfig: {
          memberKey: this.member.key,
          count: 1000,
        },
      });
      this.isLoading = false;
    },
    communityLocation(event) {
      window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        "{subdomain}",
        event.subdomain || "app"
      ).replace("{slug}", event.slug);
    },
    getMemberRoles() {
      return this.$store.dispatch("getRoles", { memberKey: this.member.key });
    },
    async getRoles(page) {
      const response = await this.$store.dispatch("getRoles", {
        page,
        perPage: 15,
      });
      this.rolesOptions = response.data;
    },
    setRoles() {
      // Removed roles
      const removedRoles = [];
      this.memberRoles.forEach((item) =>
        !this.rolesSelected.includes(item) ? removedRoles.push(item.key) : null
      );
      // Assigned Roles
      const keys = [];
      this.communityRoles.map(({ key }) => {
        this.rolesSelected.map((item) =>
          key === item.key ? keys.push(key) : null
        );
      });
      return this.$store.dispatch("setRoles", {
        memberKey: this.member.key,
        roleKeys: keys,
        deleteRoleKeys: removedRoles,
      });
    },
    // handleUnsuscribe() {
    //   this.isOpenedUnsubscribeModal = true;
    // },
    unsubscribeCommunity() {
      this.$store.dispatch("unsubscribeCommunity", {
        communityKey: this.currentCollective.key,
      });
      this.logOut();
    },
    logOut() {
      this.$store.dispatch("logout");
    },
    async createConection() {
      const response = await this.$store.dispatch('getItems', {
        itemType: 'myMemberRelationships',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          orderByDate: -1,
          memberKey: this.member.key,
          count: 15,
        },
      });
      const canConnect = response.data.filter(item=> {
        return item.key === this.loggedMember.key && item.hasRelation
      });
      if (canConnect.length === 0) {
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'myMemberRelationships',
            requestConfig: {
              memberKeys: [this.loggedMember.key, this.member.key],
            },
          },
        });
        await this.getMemberRelations();
        await this.fetchUser(this.username);
      }
    },
    async getMemberRelations() {
      await this.$store.dispatch('getItems', {
        itemType: 'memberRelation',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          orderByDate: -1,
          memberKey: this.member.key,
          count: 15,
        },
      });
    },
    getRelations(relations) {

      this.hasRelation = relations.filter(item => item.key === this.loggedMember.key).length > 0;
      this.keyAbout = randomString(10);
      // this.wantConnection =false
    },
    async handleRemovedRelation(response) {
      if (response === true) {
        await this.fetchUser(this.username);
        this.hasRelation = false;
        this.wantConnection = false
      }
    },
    async handleConnectRelation(response) {
      await this.fetchUser(this.username);
      if (response === true) {
        this.hasRelation = true;
        this.wantConnection = true
      } else {
        this.hasRelation = false;
        this.wantConnection = false
      }
    },
    getColor(type) {
      const types = {
        Event: 'success',
        Project: 'info',
        Challenge: 'warning',
        Subcommunity: 'secondary',
      };
      return types[type];
    },
    translateTranslationTable,
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/page-profile.scss";
.text-editor::v-deep {
  height: auto;
  .ql-editor {
    min-height: 96px;
  }
}
.select-control {
  flex: 1 1 auto;
}
.placeholder {
  height: 160px;
}
.edit-icon {
  position: relative;
  float: right;
  top: -43px;
  cursor: pointer;
}
.edit-icon2 {
  position: relative;
  float: right;
  top: -18px;
  right: -15px;
  cursor: pointer;
}
.edit-icon-alert {
  position: relative;
  float: right;
  top: -160px;
  cursor: pointer;
}
.edit-icon-mobile {
  top: -27px;
}
.dropdown-member {
  [dir] .dropdown-menu[x-placement="bottom-start"],
  [dir] .dropdown-menu[x-placement="bottom-end"] {
    margin-top: 50 !important;
  }
}
.alerta2 {
  display: none;
}
@media (max-width: 991px) {
  .alerta {
    display: none;
  }
  .alerta2 {
    display: block;
  }
}
</style>
